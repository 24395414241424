import { signal } from '@angular/core';
import { createInjectable } from 'ngxtension/create-injectable';

export const TenantService = createInjectable(
  () => {
    const currentTenant = signal<string | undefined>(undefined);

    return {
      get currentTenant() {
        return currentTenant();
      },
      set currentTenant(tenantId: string | undefined) {
        currentTenant.set(tenantId);
      },
    };
  },
  { providedIn: 'root' },
);
